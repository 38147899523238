import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/views/index'
import Settle from '@/views/settle'
import Pay from '@/views/pay'
import Success from '@/views/success'
import BindCard from '@/views/bindCard'
import BindCardStep from '@/views/bindCard-step'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/layout'
  },
  {
    path: '/layout/:username/:password',
    name:'layout',
    component: Layout
  },
  {
    path: '/settle',
    name:'settle',
    component: Settle
  },
  {
    path: '/pay',
    name:'pay',
    component: Pay
  },
  {
    path: '/success',
    name:'success',
    component: Success
  },
  {
    path: '/bindCard',
    name:'bindCard',
    component: BindCard
  },
  {
    path: '/bindCardStep',
    name:'bindCardStep',
    component: BindCardStep
  },
]

const router = new VueRouter({
  routes
})

export default router
