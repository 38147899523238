<template>
  <div class="app-contanier">
    <div class="back" @click="back">
      <van-icon name="arrow-left" size="0.5rem"/>
      <span>返回</span>
    </div>

    <div class="content">
      <div class="price-row">
        <span>支付：</span>
        <div class="price"><em>¥</em>{{ totalPrice }}</div>
      </div>

      <div class="isPrint">
        <van-checkbox v-model="isPrint" icon-size="34px" checked-color="#ee0a24">是否打印小票</van-checkbox>
      </div>


      <div class="sub-title">请选择支付方式</div>
      <div class="pay-list">
        <div @click="onPay(item)" :class="['pay-item', item.disabled && 'disabled']"
             :style="{ 'background-color': item.bg }" v-for="(item, idx) in payList" :key="idx">
          <van-image width="0.88rem" height="0.88rem" fit="cover" :src="item.icon"/>
          <span>{{ item.text }}</span>
        </div>
      </div>
    </div>
    <div class="time">（{{ sendmsg }}）</div>

    <van-popup v-model="popupShow0" closeable round position="center" :close-on-click-overlay="false">
      <div class="pop">
        <div class="tit">请在下方区域扫描二维码</div>
        <div class="eg-bg">
          <van-image width="7.38rem" height="7.98rem" fit="contain" :src="require('@/assets/bg01.png')"/>
          <div class="eg">
            <van-image width="2.8rem" height="2.8rem" fit="contain" :src="require('@/assets/code.png')"/>
          </div>
          <div class="line"></div>
        </div>
      </div>
    </van-popup>

    <van-popup v-model="popupShow1" closeable round position="center" :close-on-click-overlay="false">
      <div class="pop">
        <div class="tit">请在下方区域刷饭卡</div>
        <div class="eg-bg">
          <van-image width="7.38rem" height="7.98rem" fit="contain" :src="require('@/assets/bg02.png')"/>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {getData} from '@/api/home';
import Speech from 'speak-tts'

let music = new Audio();

export default {
  data() {
    return {
      sendmsg: '',
      totalPrice: 0.00,
      payList: [{
        icon: require('@/assets/icon-scan.png'),
        type: 0,
        text: "支付码支付",
        bg: '#FFF1CF',
        mode: '会员码',
        disabled: false
      }, {
        icon: require('@/assets/icon-card.png'),
        type: 1,
        text: "刷饭卡",
        bg: '#E4F2FF',
        mode: '饭卡',
        disabled: false
      }, {
        icon: require('@/assets/icon-wx.png'),
        type: 0,
        text: "微信支付",
        bg: '#E0FFE0',
        mode: '微信',
        disabled: false
      }, {
        icon: require('@/assets/icon-alipay.png'),
        type: 0,
        text: "支付宝支付",
        bg: '#E0EFFF',
        mode: '支付宝',
        disabled: false
      }],
      flag: true,
      popupShow0: false,
      popupShow1: false,
      orderNumber: '',
      isPrint: false,
      successAutoplay: false,
      failAutoplay: false,
      speech: null,
      carnum: 0
    }
  },
  created() {
    let totalPrice = this.$route.params.totalPrice;
    let orderNumber = this.$route.params.orderNumber;
    let carnum = this.$route.params.carnum;
    if (totalPrice) {
      this.totalPrice = totalPrice;
      this.orderNumber = orderNumber;
      this.carnum = carnum
    }
    this.timeDown();
    let that = this;
    let str = '';
    document.onkeydown = function (e) {
      var key = window.event.key;
      if (that.flag) {
        if (key === 'Enter') {
          that.pay(str)
          str = ''
        } else if (key.length == 1) {
          str = str + key;
        }
      }

    }

    // jm店需要展示勾选打印小票
    this.isPrint = this.$storage.get('store').storeId === 6;
  },
  mounted() {
    this.speechInit()
  },
  methods: {
    speechInit() {
      this.speech = new Speech()
      this.speech.setLanguage("zh-CN")
      this.speech.init().then(() => {})
      this.speckFn()
    },
    speckFn() {
      const text = `共计${this.carnum}件商品，需支付${this.totalPrice}元`
      this.speech.speak({ text }).then(() => {
        console.log("读取成功");
      });
    },
    goSettle() {
      this.$router.push({name: "settle"})
    },
    timeDown() {
      let timer = 60;
      this.sendmsg = timer;
      this.timeFun = setInterval(() => {
        --timer;
        this.sendmsg = timer;
        if (timer == 0) {
          this.back();
        }
      }, 1000);
    },
    back() {
      clearInterval(this.timeFun);
      this.$router.go(-1);
    },
    onPay(item) {
      this.payMode = item.mode;
      this[`popupShow${item.type}`] = true;
    },
    sussessMp3() {
      music.src = require('@/assets/pay_success.mp3')
      music.play();
    },
    FailMp3() {
      music.src = require('@/assets/pay_fail.mp3')
      music.play();
    },
    pay(str) {
      if (str) {
        this.flag = false;
        this.popupShow0 = false;
        this.popupShow1 = false;
        getData('pay', {
          payCode: str,
          orderNumber: this.orderNumber
        }).then(res => {
          // 播放成功音频
          this.sussessMp3();
          this.$router.push({
            name: "success",
            params: {'totalPrice': this.totalPrice, 'orderNumber': this.orderNumber, 'isPrint': this.isPrint ? '1' : ''}
          })
          clearInterval(this.timeFun);
        }).catch(error => {
          // 播放失败音频
          this.FailMp3();
          this.flag = true;
        })
      }

    },

  }
}
</script>

<style lang="less" scoped>
.app-contanier {
}

.back {
  margin: 24px 0 14px 24px;
  display: flex;
  align-items: center;
}

.content {
  padding: 20px 10px 15px;
  width: 90%;
  margin: auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
  border-radius: 2px;
  box-sizing: border-box;
}

.price-row,
.sub-title {
  text-align: center;
}

.price-row {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;

  .price {
    color: #DB0B11;

    em {
      font-size: 20px;
      font-style: normal;
    }
  }
}

.isPrint {
  margin: 16px auto 0;

  .van-checkbox {
    justify-content: center;
  }

}

.sub-title {
  padding: 15px 0;
  color: #333;
  font-size: 16px;
}

.pay-item {
  padding: 14px 0 14px 87px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  // justify-content: center;
  font-size: 20px;
  border-radius: 8px;

  span {
    margin-left: 15px;
  }
}

.pay-item.disabled {
  opacity: .6;
}

.time {
  margin-top: 15px;
  text-align: center;
  font-size: 18px;
}

.pop {
  padding-top: 25px;
  width: 315px;
  height: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .tit {
    font-size: 18px;
  }

  .eg-bg {
    // margin-top: 25px;
    // width: 100%;
    position: relative;

    .eg {
      position: absolute;
      bottom: 0px;
      right: 32px;

      animation: shaking 1.25s linear infinite;
      -webkit-animation: shaking 1.25s linear infinite;
    }


    .line {
      position: absolute;
      width: 111px;
      height: 3px;
      right: 30px;
      bottom: 75px;
      background-color: #FF0000;
    }
  }
}

@keyframes shaking {

  20% {
    bottom: 20px;
  }

  40% {
    bottom: 30px;
  }

  60% {
    bottom: 40px;
  }

  80% {
    bottom: 20px;
  }

  100% {
    bottom: 0;
  }
}
</style>
