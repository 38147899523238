<template>
  <div class="app-contanier">
    <div class="top">
      <van-button @click="back" round class="cancel" size="mini" type="default">立即结束({{ sendmsg }}s)</van-button>
    </div>
    <div class="content">
      <van-icon name="checked" size="1.4rem" color="#DA0B11" />
      <div class="tip">支付成功</div>
      <div class="sub-tip">欢迎下次光临</div>
    </div>
  </div>
</template>

<script>
import { getLodop } from '@/utils/LodopFuncs'
export default {
  name: 'success',
  data() {
    return {
      loginParams: {},
      sendmsg: 3,
      totalPrice: '',
      isPrint:''
    }
  },
  created() {
    this.timeDown();
    this.loginParams = this.$storage.get('login');
    this.totalPrice = this.$route.params.totalPrice || '';
    this.orderNumber = this.$route.params.orderNumber || '';
    this.isPrint = this.$route.params.isPrint || '';
  },
  mounted() {
    // if(this.$storage.get('print') == '1'){
    //     this.$storage.remove('print')
    //     this.$router.push({ name: "layout", params: this.loginParams })
    // } else {
    //     this.$storage.set('print', '1')
    // }
    if(this.isPrint){
      this.print();
    }else{
      this.$storage.set('list', [])
    }

  },
  methods: {
    timeDown() {
      let timer = 3;
      this.sendmsg = timer;
      this.timeFun = setInterval(() => {
        --timer;
        this.sendmsg = timer;
        if (timer == 0) {
          this.back();
        }
      }, 1000);
    },
    back() {
      clearInterval(this.timeFun);
      this.$router.push({ path: `/layout/${this.loginParams.username}/${this.loginParams.password}` })
    },

    print() {
      let list = this.$storage.get('list');
      this.$storage.set('list', [])
      LODOP = getLodop()
      let store = this.$storage.get('store').store;

      const now = new Date();
      const year = now.getFullYear();
      const month = ('0' + (now.getMonth() + 1)).slice(-2);
      const day = ('0' + now.getDate()).slice(-2);
      const hours = ('0' + now.getHours()).slice(-2);
      const minutes = ('0' + now.getMinutes()).slice(-2);
      const seconds = ('0' + now.getSeconds()).slice(-2);
      const formattedTime = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
      LODOP.SET_LICENSES("苏州安景信息科技有公司", "464536580837475917689190562356", "", "");

      LODOP.PRINT_INIT('订单单据') //打印初始化
      LODOP.SET_PRINT_PAGESIZE(3, '80mm') //设定纸张大小
      LODOP.ADD_PRINT_HTM('0', '0', '70mm', '10mm', `<div style="text-align: center;font-size: 18px;font-weight: 600;">订单单据</div>`) //增加超文本项
      LODOP.ADD_PRINT_HTM('9mm', '0', '70mm', '10mm', `<div style="margin: 10px 0;text-align: center;font-size: 16px;font-weight: 600;">${store.name}</div>`) //增加超文本项
      LODOP.ADD_PRINT_HTM('19mm', '0', '70mm', '10mm', `<div style="height: 20px;"><div style="float: left;">订单号：</div><div style="float: right;">${this.orderNumber || ""}</div></div>`) //增加超文本项
      LODOP.ADD_PRINT_HTM('25mm', '0', '70mm', '10mm', `<div style="height: 20px;font-size: 14px;"><div style="float: left;">日期：</div><div style="float: right;">${formattedTime}</div></div>`) //增加超文本项
      LODOP.ADD_PRINT_HTM('35mm', '0', '70mm', '10mm', `<div style="height: 1px;border-top: 3px dashed #000;"></div>`) //增加超文本项


      let str = '';
      let count = 0;
      list.map(t => {
        const exp = /[\u4e00-\u9fa5\d]/g
        count += Math.ceil(t.name.match(exp).length / 12)
      })
      for (var i = 0; i < list.length; i++) {
        str += `<div style=" overflow: hidden;font-size: 14px;margin-bottom: 8px;"><div style="float: left;width:180px">${list[i].name}</div><div style="float: left;width:40px;text-align: right"><span style="font-size: 8px;">X</span>${list[i].quantity}</div><div style="float: right;">${list[i].retailPrice}</div></div>`;
      }

      LODOP.ADD_PRINT_HTM('40mm', '0', '70mm', count * 10 + 'mm', str) //增加超文本项


      LODOP.ADD_PRINT_HTM(47 + count * 6 + 'mm', '0', '70mm', '10mm', `<div style="height: 1px;border-top: 3px dashed #000;"></div>`) //增加超文本项
      LODOP.ADD_PRINT_HTM(50 + count * 6 + 'mm', '0', '70mm', '10mm', `<div style="height: 20px;font-size: 14px;"><div style="float: left;">总计</div><div style="float: right;">${this.totalPrice}</div></div>`) //增加超文本项
      LODOP.ADD_PRINT_HTM(58 + count * 6 + 'mm', '0', '70mm', '10mm', `<div style="height: 1px;border-top: 3px dashed #000;"></div>`) //增加超文本项
      LODOP.ADD_PRINT_HTM(59 + count * 6 + 'mm', '0', '70mm', '10mm', `<div style="height: 1px;border-top: 3px dashed #000;"></div>`) //增加超文本项
      LODOP.ADD_PRINT_HTM(62 + count * 6 + 'mm', '0', '70mm', '10mm', `<div style="text-align: center;font-size: 22px;font-weight: 600;">谢谢惠顾</div>`) //增加超文本项

      // setTimeout(LODOP.PRINT(), 500)
      LODOP.PRINT()
    }
  },

}
</script>

<style lang="less" scoped>
.top {
  padding: 20px 15px;
  font-size: 17px;
  display: flex;
  justify-content: flex-end;

  .cancel {
    width: 102px;
    height: 30px;
    font-size: 12px;
  }
}

.content {
  margin-top: 118px;
  display: flex;
  align-items: center;
  flex-direction: column;

  .tip {
    margin-top: 20px;
    font-size: 18px;
  }

  .sub-tip {
    color: #666;
    margin-top: 10px;
  }
}
</style>
