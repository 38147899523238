
import request from '@/utils/request'

export function getData (pageType,data) {
  return request({
    url: '/pay/' + pageType,
    method: 'POST',
    data
  })
}
export function deviceLogin (data) {
  return request({
    url: '/deviceLogin',
    method: 'POST',
    data
  })
}

export function mealCardCheck (data) {
  return request({
    url: '/mealCard/check',
    method: 'GET',
    params:data
  })
}
