<template>
    <div class="app-contanier">
        <div class="back" @click="back">
            <van-icon name="arrow-left" size="0.5rem" />
            <span>返回</span>
        </div>

        <div class="content">
            <div class="pop">
                <div class="tit1">绑定饭卡</div>
                <div class="tit">请将饭卡放置读卡区</div>
                <div class="eg-bg">
                    <van-image width="7.38rem" height="7.98rem" fit="contain" :src="require('@/assets/bg02.png')" />
                    <!-- <div class="eg">
                        <van-image width="2.8rem" height="2.84rem" fit="contain" :src="require('@/assets/eg02.png')" />
                    </div>
                    <div class="line"></div> -->
                </div>
            </div>
        </div>

        <div class="time">（{{ sendmsg }}）</div>


    </div>
</template>
  
<script>
import { mealCardCheck } from '@/api/home';
import { Toast } from 'vant'
export default {
    data() {
        return {
            sendmsg: '',
            flag:true
        }
    },
    created() {
        this.timeDown();
        let that = this;
        let str = '';
        document.onkeydown = function (e) {
            var key = window.event.key;
            console.log(key,that.flag)
            if (that.flag) {
                if (key === 'Enter') {
                    if (str) {
                        that.mealCardCheck(str)
                        str = ''
                    }

                } else {
                    str = str + key;
                }
            }

        }
    },
    methods: {
        timeDown() {
            let timer = 30;
            this.sendmsg = timer;
            this.timeFun = setInterval(() => {
                --timer;
                this.sendmsg = timer;
                if (timer == 0) {
                    this.back();
                }
            }, 1000);
        },

        back() {
            clearInterval(this.timeFun);
            this.$router.go(-1);
        },
        mealCardCheck(str) {
            this.flag = false;
            let store = this.$storage.get('store').store;
            mealCardCheck({ 'card': str, 'storeId': store.id }).then(res => {
                this.flag = true;
                if (res.data) {
                    Toast({
                        message: '该饭卡已被绑定',
                        type: 'fail',
                        duration: 2000
                    })
                } else {
                    this.$router.push({ name: "bindCardStep", params: { 'card': str } })
                }
            }).catch(() => {
                this.flag = true;
            })
        },

    }
}
</script>
  
<style lang="less" scoped>
.app-contanier {
    padding-bottom: 40px;
}

.back {
    margin: 24px 0 44px 24px;
    display: flex;
    align-items: center;
}

.content {
    padding: 35px 8px 15px;
    width: 90%;
    margin: auto;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
    border-radius: 2px;
    box-sizing: border-box;
}

.time {
    margin-top: 15px;
    text-align: center;
    font-size: 18px;
}

.pop {
    // padding-top: 25px;
    width: 315px;
    // height: 390px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .tit1 {
        font-size: 24px;
        letter-spacing: 2px;
    }

    .tit {
        margin: 15px 0 25px;
        font-size: 18px;
    }

    .eg-bg {
        // margin-top: 25px;
        // width: 100%;
        position: relative;

        .eg {
            position: absolute;
            bottom: 0px;
            right: 58px;

            animation: shaking 1.25s linear infinite;
            -webkit-animation: shaking 1.25s linear infinite;
        }



        .line {
            position: absolute;
            width: 90px;
            height: 3px;
            right: 66px;
            bottom: 75px;
            background-color: #FF0000;
        }
    }
}

@keyframes shaking {

    20% {
        bottom: 20px;
    }

    40% {
        bottom: 30px;
    }

    60% {
        bottom: 40px;
    }

    80% {
        bottom: 20px;
    }

    100% {
        bottom: 0;
    }
}
</style>
  