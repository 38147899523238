<template>
  <div class="app-contanier">
    <div class="top">
      <div class="name">{{ store.name }}</div>
      <van-button class="cancel" @click="cancel" size="mini" type="default">取消交易</van-button>
    </div>
    <template v-if="proList.length > 0">
      <div class="pro-list">
        <div class="pro-item" v-for="(item, index) in proList" :key="item.id">
          <van-image class="half-price-tag" width="1.4rem" height="0.45rem" fit="fill" :src="require('@/assets/half-price-tag.png')"
                     v-if="item.promotion?.promotionType === 0"/>
          <van-image width="1.4rem" height="1.4rem" fit="cover" :src="item.coverUrl"/>
          <div class="item-cont">
            <div class="item-cont-top">
              <div class="name">
                <span style="margin-right: 5px;">{{ item.name }}</span>
              </div>

              <van-icon name="delete-o" size=".4rem" @click="onDelete(index)"/>
            </div>
            <div class="item-cont-btm">
              <div class="spec">规格：{{ item.unit }}</div>
              <van-stepper v-model="item.quantity" disable-input min="1" @change="numberChange" integer
                           input-width=".8rem"
                           button-size=".5rem"/>
              <div class="price" v-if="item.promotion?.promotionType === 1">
                <span class="current">¥{{ item.currentPrice }}</span>
                <span class="original">¥{{ item.retailPrice }}</span>
              </div>
              <div class="price" v-else>
                <span class="current">¥{{ item.retailPrice }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <div class="empty" v-else>
      <van-image width="5.4rem" height="3.6rem" fit="cover" :src="require('@/assets/empty.png')"/>
      <p>暂无商品，快去添加商品吧</p>
    </div>

    <!-- <van-popup v-model="barcodeShow" position="center" :style="{ width: '90%' }" :close-on-click-overlay="false">
        <div class="barcode-pop">
            <div class="title">请输入条形码<van-icon @click="cancel" name="cross" size=".45rem" /></div>
            <div class="cont">
                <van-field v-model="barcode" type="number" border />
            </div>
            <div class="pop-btn">
                <van-button class="btn" type="default" @click="cancel">取消</van-button>
                <van-button class="btn" color="#DA0B11">确定</van-button>

            </div>
        </div>
    </van-popup> -->


    <div class="btn-tool">
      <!-- <div class="tool" @click="goBound">
          <van-icon size=".4rem" :name="require('@/assets/icon-card2.png')" />
          &nbsp;饭卡绑定
      </div> -->
      <!-- <div class="tool" @click="openBarcode">
          <van-icon size=".4rem" :name="require('@/assets/icon-barcode.png')" />
          &nbsp; 输入条形码
      </div> -->
    </div>
    <div class="bottom">
      <div class="bottom-left">
        <div class="total-box">
          <div class="total">
            <span class="title">合计：</span>
            <span class="sign">¥</span>
            <span class="amount">{{ totalPrice }}</span>
            <span class="num">(共{{ carnum }}件）</span>
          </div>
        </div>
        <div class="discount-box" v-if="discountPrice > 0">
          <span>共优惠：¥{{discountPrice}}</span>
        </div>
      </div>
      <div class="btn" @click="onPay">
        <van-button :loading="subLoading" loading-text="提交中..." :disabled="carnum == 0"
                    color="linear-gradient(to right, #EA0B12, #D80B11)" block>确认付款
        </van-button>
      </div>
    </div>
  </div>
</template>
<script>
import {getData} from '@/api/home';
import {Toast} from 'vant';

let music1 = new Audio();
export default {
  data() {
    return {
      store: {},
      barcodeShow: false,
      barcode: '',
      timer: null,
      proList: [],
      totalPrice: 0.00,
      carnum: 0,
      flag: true,
      subLoading: false,
      discountPrice: 0
    }
  },
  methods: {
    goBound() {
      this.$router.push({name: "bindCard"});
    },
    sussessMp3() {
      music1.src = require('@/assets/add_product_success.mp3')
      music1.play();
    },
    FailMp3() {
      music1.src = require('@/assets/add_product_fail.mp3')
      music1.play();
    },
    onSearch(barcode) {
      this.flag = false;
      const loading = Toast.loading({
        message: '加载中...',
        duration: 0,
      });
      getData('product', {'barcode': barcode}).then(res => {
        if (res.code === 200) {
          loading.clear()
          let data = res.data;
          if (data && data.id) {
            this.flag = true;
            data.quantity = 1;
            let proList = this.proList;
            let idx = proList.findIndex(ele => ele.id === data.id);
            if (idx >= 0) {
              const it = proList[idx]
              // 当前数量+1
              data.quantity = it.quantity + 1
              // 基础信息会变更 需覆盖更新赋值
              proList.splice(idx, 1, {...it, ...data})
            } else {
              proList.unshift(data)
            }
            this.proList = proList;
            this.sussessMp3();
            this.computePrice();
          } else {
            this.FailMp3();
            this.flag = true;
            loading.clear()
            Toast({
              message: '暂无该商品',
              type: 'fail',
              duration: 2 * 1000
            })
          }
        }

      }).catch(error => {
        loading.clear();
        this.FailMp3();
        this.flag = true;
      });
    },
    numberChange() {
      this.computePrice()
    },
    onDelete(index) {
      this.proList.splice(index, 1);
      this.computePrice();
    },
    computePrice() {
      let proList = this.proList;
      let originalPrice = 0;
      let carnum = 0;
      let tempArr = [];
      proList.forEach(t => {
        if (t.quantity > 0) {
          tempArr.push(t)

          // 原始总金额 = 数量 * 原价
          originalPrice = this.bignumber(originalPrice).plus(this.bignumber(t.quantity).times(t
            .retailPrice).toNumber()).toNumber()
          carnum += t.quantity
        }
      });

      tempArr.map(t => {
        if (t.promotion?.id) {
          // 计算折扣单价展示
          const ratio = this.bignumber((t.promotion?.promotionType === 0 ? 50 : t.promotion?.discountRatio) / 100).toNumber()
          t.currentPrice = this.bignumber(t.retailPrice).times(ratio).toNumber()

          /**
           * @promotionType
           * 0 第二件半价促销
           * 1 折扣促销
           * */
          if (t.promotion?.promotionType === 0) {
            if (t.quantity === 1) {
              t.totalPrice = this.bignumber(t.quantity).times(t.retailPrice).toNumber()
            } else {
              const evenFlag = t.quantity % 2 === 0 // 是否是偶数
              if (evenFlag) {
                // @偶数 第二件半价促销公式 = 数量 * 原价 * 0.75
                t.totalPrice = this.bignumber(t.quantity).times(t.retailPrice).times(0.75).toNumber()
              } else {
                // @奇数 第二件半价促销公式 = (先数量 - 1) * 原价 * 0.75 + (一件商品的原价)
                t.totalPrice = this.bignumber(t.quantity - 1).times(t.retailPrice).times(0.75).plus(t.retailPrice).toNumber()
              }
            }
          } else if (t.promotion?.promotionType === 1) {
            t.totalPrice = this.bignumber(t.quantity).times(t.currentPrice).toNumber()
          }
        } else {
          // 普通计算
          t.totalPrice = this.bignumber(t.quantity).times(t.retailPrice).toNumber()
        }
      })

      // (包含折扣)总金额
      this.totalPrice = tempArr.map(t => t.totalPrice).reduce((a, b) => this.bignumber(a).plus(b), 0)
      // 优惠金额 = 原始总金额 - (包含折扣)总金额
      this.discountPrice = this.bignumber(originalPrice).minus(this.totalPrice)

      this.carnum = carnum
      // this.$store.commit('SET_CART', tempArr);
      this.$storage.set('list', tempArr)
    },
    cancel() {
      this.$router.go(-1);
      // this.$store.commit('SET_CART', []);
      this.$storage.set('list', [])
    },
    // openBarcode() {
    //     this.barcodeShow = true;
    //     flag = true;
    // },
    // cancel() {
    //     this.barcode = '';
    //     this.barcodeShow = false;
    //     flag = false;
    // },
    onPay() {
      let proList = this.proList;
      proList.forEach((element, index) => {
        element.productId = element.id
      });
      this.subLoading = true;
      getData('order', {
        shoppingOrderDetailList: proList
      }).then(res => {
        this.$router.push({
          name: "pay",
          params: {'totalPrice': this.totalPrice, 'orderNumber': res.msg, carnum: this.carnum}
        })
      }).catch(error => {
        this.subLoading = false;
      });

    },
  },
  created() {
    let barcode = this.$route.params.barcode;
    if (barcode && barcode.length) {
      this.onSearch(barcode)
    }
    let that = this;
    let str = '';

    document.onkeydown = function (e) {
      var key = window.event.key;
      // console.log(key,flag)
      if (that.flag) {
        if (key === 'Enter') {
          that.onSearch(str)
          str = ''
        } else {
          str = str + key;
        }
      }
      if (key === 'Enter') {
        e.preventDefault()
      }

    }
  },
  mounted() {
    this.store = this.$storage.get('store').store;
    let cartList = this.$storage.get('list');
    if (cartList?.length > 0) {
      this.proList = cartList;
      this.computePrice();
    }
  },
}
</script>

<style lang="less" scoped>
.app-contanier {
  padding: 23px 15px 75px;
}

.top {
  font-size: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .cancel {
    width: 90px;
    height: 30px;
    font-size: 12px;
  }
}

.pro-list {
  padding-top: 10px;
}

.pro-item {
  padding: 20px 15px;
  display: flex;
  align-items: center;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  margin-top: 10px;
  position: relative;

  .half-price-tag {
    position: absolute;
    top: 0;
    left: 0;
  }

  .item-cont {
    flex: 1;
    padding-left: 10px;
  }

  .item-cont-top {
    display: flex;

    // align-items: flex-start;
    .name {
      flex: 1;
      padding-right: 20px;
      line-height: 20px;
    }
  }

  .item-cont-btm {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .spec {
      width: 70px;
    }

    .van-stepper {
      width: 80px;
      text-align: center;
    }

    .price {
      display: flex;
      align-items: center;
      flex: 1;
      color: #D80B11;

      .current {
        margin-left: auto;
      }

      .original {
        font-size: 12px;
        color: #666;
        margin-left: 10px;
        text-decoration: line-through;
      }
    }
  }

  /deep/ .van-stepper__minus {
    background-color: #D1D1D1;
    color: #fff;
  }

  /deep/ .van-stepper__plus {
    background-color: #D80B11;
    color: #fff;
  }

  /deep/ .van-stepper__input {
    background-color: transparent;
  }
}

.empty {
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bottom {
  border-top: 1px solid #D3D3D3;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  height: 75px;
  box-sizing: border-box;
  background-color: #fff;

  .bottom-left {
    display: flex;
    flex-direction: column;
    flex: 1;
    font-size: 14px;

    .total-box {
      font-weight: bold;
      display: inline-block;
      //display: flex;
      //align-items: center;

      margin-bottom: 5px;

      .total {
        display: inline-block;
        color: #D90B11;

        .title {
          color: #000;
        }

        .sign {
          color: #D90B11;
        }

        .amount {
          font-size: 28px;
          line-height: 29px;
          color: #D90B11;
        }

        .num {
          color: #333;
          margin-left: 10px;
        }
      }
    }

    .discount-box {
      font-size: 12px;
      color: #FF3D00;
    }
  }

  .btn {
    width: 140px;

    /deep/ .van-button {
      height: 44px;
      letter-spacing: 1px;
      font-size: 16px;
    }
  }
}

.btn-tool {
  padding: 0 15px;
  position: fixed;
  width: 100%;
  bottom: 80px;
  left: 0;
  display: flex;
  align-items: center;
  font-size: 12px;
  box-sizing: border-box;

  .tool {
    padding: 5px;
    margin-right: 10px;
    background-color: #000000;
    color: #fff;
    display: flex;
    align-items: center;
    border-radius: 2px;
  }
}

.barcode-pop {
  padding: 15px 20px;

  .title {
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #EEEEEE;
  }

  .cont {
    margin: 15px 0;
    width: 100%;
    height: 40px;
    border: 1px solid #E70C12;
    border-radius: 4px;
    overflow: hidden;
  }

  .pop-btn {
    padding-top: 15px;
    border-top: 1px solid #EEEEEE;
    display: flex;
    justify-content: flex-end;

    .btn {
      width: 65px;
      height: 32px;
      font-size: 14px;
      letter-spacing: 1px;
      margin-left: 8px;
    }
  }
}
</style>
