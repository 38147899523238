<template>
    <div class="app-contanier">
        <div class="back" @click="back">
            <van-icon name="arrow-left" size="0.5rem" />
            <span>返回</span>
        </div>

        <div class="content">
            <div class="pop">
                <div class="tit1">绑定饭卡</div>
                <div class="tit">请用微信扫描下方二维码</div>
                <div class="qrCodeDiv" id="qrCodeDiv" ref="qrCodeDiv"></div>
            </div>
        </div>
        <div class="time">（{{ sendmsg }}）</div>


    </div>
</template>
  
<script>
import QRCode from 'qrcodejs2';//引入生成二维码插件

export default {
    data() {
        return {
            card: '',
            sendmsg: '',
            loginParams:{}
        }
    },
    created() {
        this.card = this.$route.params.card;
        this.loginParams = this.$storage.get('login')
        this.creatQRcode();
        this.timeDown();
    },
    methods: {
        timeDown() {
            let timer = 30;
            this.sendmsg = timer;
            this.timeFun = setInterval(() => {
                --timer;
                this.sendmsg = timer;
                if (timer == 0) {
                    clearInterval(this.timeFun);
                    this.$router.push({ path: `/layout/${this.loginParams.username}/${this.loginParams.password}`})
                }
            }, 1000);
        },
        back() {
            clearInterval(this.timeFun);
            this.$router.go(-1);
        },

        creatQRcode() {
            let store = this.$storage.get('store').store;
            this.$nextTick(() => {
                this.$refs.qrCodeDiv.innerHTML = '';//二维码清除
                new QRCode(this.$refs.qrCodeDiv, {
                    text: 'https://gx.onki.cn/bindCard?card='+this.card+'&storeId='+store.id,//二维码链接，参数是否添加看需求
                    width: 400,//二维码宽度
                    height: 400,//二维码高度
                    colorDark: "#333333", //二维码颜色
                    colorLight: "#ffffff", //二维码背景色
                    correctLevel: QRCode.CorrectLevel.L //容错率，L/M/H
                });
            })
        },

    }
}
</script>
  
<style lang="less" scoped>
.app-contanier {
    padding-bottom: 40px;
}

.back {
    margin: 24px 0 44px 24px;
    display: flex;
    align-items: center;
}

.content {
    padding: 35px 8px 85px;
    width: 90%;
    margin: auto;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
    border-radius: 2px;
    box-sizing: border-box;
}



.pop {
    // padding-top: 25px;
    width: 315px;
    // height: 390px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .tit1 {
        font-size: 24px;
        letter-spacing: 2px;
    }

    .tit {
        margin: 15px 0 55px;
        font-size: 18px;
    }


}

.time {
    margin-top: 15px;
    text-align: center;
    font-size: 18px;
}
</style>
  