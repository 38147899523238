/* eslint-disable indent */
import axios from 'axios'
import { Toast } from 'vant'
import { getToken, removeToken,setToken } from '@/utils/token'
import router from '@/router'
import { deviceLogin} from '@/api/home'

function deviceLoginToken(){
  let params = JSON.parse(localStorage.getItem('login'))
  deviceLogin({
    'username':params.username,
    'password':params.password,
  }).then(res=>{
    setToken(res.data.token);
    localStorage.setItem('store',JSON.stringify(res.data));
  })
}

const mAxios = axios.create({
  // baseURL: '/api', // 开发配置
  baseURL: 'https://gxcloud.onki.cn/api', // 生产配置
  timeout: 60000
})

// 请求拦截器
mAxios.interceptors.request.use(function (config) {
  if (getToken()?.length > 0 && config.headers.token === undefined) {
    config.headers.token = `${getToken()}`
  }
  return config
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})

// 响应拦截器
mAxios.interceptors.response.use(response => {
  // console.log('response--', response)
  const res = response.data
  if (res.code == 401) {
    // Toast({
    //   message: res.msg || 'fail',
    //   type: 'fail',
    //   duration: 2 * 1000
    // })
    removeToken()
    deviceLoginToken()

    return Promise.reject(res.msg || 'fail')
  } else if(res.code == 200) {
    return res
  }else{
    Toast({
      message: res.msg || 'fail',
      type: 'fail',
      duration: 2 * 1000
    })
    return Promise.reject("error");

  }
},
error => {
    const errorRes = error.response
    if (errorRes.code === 401) {
      // 不能使用this.$router (因为this不是vue组件对象无法调用$router)
      // 解决: this.$router为了拿到router路由对象, 所以直接去上面引入@/router下router对象
      // Notify({ type: 'warning', message: '身份已过期' })
      removeToken() // 先清除token, 才能让路由守卫判断失效, 放行我去登录页
      deviceLoginToken()
    } else {
      Toast({
        message: errorRes.data.msg,
        type: 'fail',
        duration: 2 * 1000
      })
    }
    return Promise.reject(error)
  }
)



// 导出自定义函数, 参数对象解构赋值
export default ({ url, method = 'GET', params, data, headers }) => {
   return mAxios({
    url: url,
    method: method,
    params: params,
    data: data,
    headers: headers
   })
}
