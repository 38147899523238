import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userPhoto: '',
    nickName: '',
    cartList:[],
    successAutoplay:false,
    failAutoplay:false,
  },
  getters: {
    successAutoplay: state => state.successAutoplay,
    failAutoplay: state => state.failAutoplay,
    
  },
  mutations: {
    // 编码风格, mutations最好大写(区分)
    SET_USERPHOTO(state, value) {
      state.userPhoto = value
    },
    SET_NICKNAME(state, value) {
      state.nickName = value
    },
    SET_CART(state, value) {
      state.cartList = value
    },
    SET_SUCCESS_MP3(state, value) {
      state.successAutoplay = value
    },
    SET_FAIL_MP3(state, value) {
      state.failAutoplay = value
    }
  },
  actions: {},
  modules: {}
})
